import { environment } from '../environments/environment';

export class IconRegistry {
  static mapping: Record<string, string> = {
    arrow_drop_down: 'e5c5',
    arrow_back: 'e5c4',
    arrow_left: 'e5de',
    arrow_right: 'e5df',
    audio_file: 'eb82',
    audiotrack: 'e3a1',
    auto_graph: 'e4fb',
    browse_activity: 'f8a5',
    check: 'e5ca',
    check_box: 'e834',
    check_box_outline_blank: 'e835',
    close: 'e5cd',
    code: 'e86f',
    dark_mode: 'e51c',
    email: 'e0be',
    expand_less: 'e5ce',
    expand_more: 'e5cf',
    fullscreen: 'e5d0',
    get_app: 'e884',
    headphones: 'f01f',
    info: 'e88e',
    info_outline: 'e88f',
    launch: 'e895',
    light_mode: 'e518',
    link: 'e157',
    lock: 'e897',
    mail: 'e158',
    menu: 'e5d2',
    music_note: 'e405',
    navigate_before: 'e408',
    navigate_next: 'e409',
    open_in_new: 'e89e',
    outbound: 'f228',
    play_circle: 'e039',
    public: 'e80b',
    report_problem: 'e8b2',
    right_panel_close: 'f705',
    search: 'e8b6',
    sentiment_dissatisfied: 'e811',
    sentiment_neutral: 'e812',
    sentiment_satisfied: 'e813',
    sentiment_satisfied_alt: 'e0ed',
    sentiment_very_dissatisfied: 'e814',
    sentiment_very_satisfied: 'e815',
    share: 'e80d',
    shuffle: 'e043',
    star: 'e838',
    star_border: 'e83a',
    star_half: 'e839',
    star_outline: 'f06f',
    translate: 'e8e2',
    visibility: 'e8f4',
    volume_up: 'e050',
    warning_amber: 'f083',
    waves: 'e176',
  };

  static getIcon(icon: string): string {
    const mappedIcon = this.mapping[icon];

    if (mappedIcon) {
      return `"\\${mappedIcon}"`;
    }

    if (!environment.production) {
      console.warn('IconRegistry: did not find mapping for ', icon);
    }

    return `"${icon}"`;
  }
}
